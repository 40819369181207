import { COUNTRY_CODE } from '@constants/country';

export const getCountryCode = () => {
  if (typeof window !== 'undefined') {
    localStorage.getItem('country_code') ||
      localStorage.setItem('country_code', COUNTRY_CODE.INDONESIA);
    return localStorage.getItem('country_code');
  }

  return COUNTRY_CODE.INDONESIA;
};

export const setCountryCode = countryCode => {
  localStorage?.setItem('country_code', countryCode);
};
