export const COUNTRY_CODE = {
  INDONESIA: 'ID',
  PHILIPPINES: 'PH',
  MALAYSIA: 'MY'
};

export const CURRENCY_CODE = {
  IDR: 'IDR',
  PHP: 'PHP',
  MYR: 'MYR'
};

export const CURRENCY_COUNTRY_CODE_MAP = {
  [CURRENCY_CODE.IDR]: COUNTRY_CODE.INDONESIA,
  [CURRENCY_CODE.PHP]: COUNTRY_CODE.PHILIPPINES
};

export const COUNTRY_PHONE_CODE = {
  [COUNTRY_CODE.INDONESIA]: 62,
  [COUNTRY_CODE.PHILIPPINES]: 63,
  [COUNTRY_CODE.MALAYSIA]: 60
};

export const COUNTRY_CURRENCY = {
  [COUNTRY_CODE.INDONESIA]: CURRENCY_CODE.IDR,
  [COUNTRY_CODE.PHILIPPINES]: CURRENCY_CODE.PHP,
  [COUNTRY_CODE.MALAYSIA]: CURRENCY_CODE.MYR
};

export const COUNTRY_LOCALE = {
  [COUNTRY_CODE.INDONESIA]: 'id-ID',
  [COUNTRY_CODE.PHILIPPINES]: 'ph-PH',
  [COUNTRY_CODE.MALAYSIA]: 'ms-MY'
};

export const COUNTRY_DEFAULT_BANK = {
  [COUNTRY_CODE.INDONESIA]: 'MNC',
  [COUNTRY_CODE.PHILIPPINES]: 'SBC'
};

export const COUNTRY_UTC_OFFSET = {
  [COUNTRY_CODE.INDONESIA]: 7,
  [COUNTRY_CODE.PHILIPPINES]: 8,
  [COUNTRY_CODE.MALAYSIA]: 8
};
